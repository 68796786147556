<template>
  <div :class="arrowClass"></div>
</template>
<script>
import { SLIDE_ARROW, EMPTY_STRING } from '@/constants'

const CSN_SLIDE_ARROW = 'csn-slide-arrow'
const CSN_SLIDE_ARROW_BACK = 'csn-slide-arrow-back'

export default {
  name: SLIDE_ARROW,
  props: {
    isBack: Boolean,
  },
  computed: {
    arrowClass() {
      return [
        CSN_SLIDE_ARROW,
        this.isBack ? CSN_SLIDE_ARROW_BACK : EMPTY_STRING,
      ]
    },
  },
}
</script>
